// extracted by mini-css-extract-plugin
export var addWorkmap = "MyWorkMapsView-module--addWorkmap--1Gtz4";
export var container = "MyWorkMapsView-module--container--eQuA6";
export var disabled = "MyWorkMapsView-module--disabled--Z7ItX";
export var enabled = "MyWorkMapsView-module--enabled--uBAKp";
export var heading = "MyWorkMapsView-module--heading---xJb9";
export var icon = "MyWorkMapsView-module--icon--n8g3Y";
export var label = "MyWorkMapsView-module--label--UcyNd";
export var main = "MyWorkMapsView-module--main--SVhC-";
export var myWorkMapsView = "MyWorkMapsView-module--myWorkMapsView--Kp1Z0";
export var paragraph = "MyWorkMapsView-module--paragraph--1Goem";
export var table = "MyWorkMapsView-module--table--lM+fW";
export var tableData = "MyWorkMapsView-module--tableData--15iqa";
export var tableHeader = "MyWorkMapsView-module--tableHeader--Klkcv";